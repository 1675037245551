<template>
  <div>
    <v-card class="mx-auto" max-width="1000">
      <v-img class="white--text align-end" src="/img/banner.jpg">
        <v-card-title>{{ $t('informationPage.banner') }}</v-card-title>
      </v-img>

      <v-card-text class="text--primary" style="padding-bottom:0px;">
        <h3>{{ $t('informationPage.securityInformation.header') }}</h3>
        <p>
          {{ $t('informationPage.securityInformation.description') }}
          <v-btn
            color="primary"
            x-small
            outlined
            rounded
            to="/information/security"
          >{{ $t('generic.readmore') }}</v-btn>
        </p>
        <h3>{{ $t('informationPage.GDPRInformation.header') }}</h3>
        <p>
          {{ $t('informationPage.GDPRInformation.description') }}
          <v-btn
            color="primary"
            x-small
            outlined
            rounded
            to="/information/gdpr"
          >{{ $t('generic.readmore') }}</v-btn>
        </p>
        <h3>{{ $t('informationPage.accessProcessInformation.header') }}</h3>
        <p>
          {{ $t('informationPage.accessProcessInformation.description') }}
          <v-btn
            color="primary"
            x-small
            outlined
            rounded
            to="/information/access"
          >{{ $t('generic.readmore') }}</v-btn>
        </p>
      </v-card-text>
      <v-container class="mx-auto" fluid>
        <v-checkbox v-model="gdpr" :label="$t('informationPage.gdpr')"></v-checkbox>
      </v-container>

      <v-card-actions>
        <v-btn
          color="primary"
          rounded
          :disabled="!gdpr"
          @click="handleLearningClick"
        >{{ $t('actions.goToTraining') }}</v-btn>
        <v-btn
          color="primary"
          :disabled="!gdpr"
          outlined
          rounded
          @click="$router.push('/request-access/')"
        >{{ $t('actions.createAccess') }}</v-btn>
        <!--<v-btn color="primary" :disabled="!gdpr" outlined rounded @click="$router.push('/renew-access/')">{{ $t('actions.renewAccess') }}</v-btn>-->
        <v-btn
          color="primary"
          :disabled="!gdpr"
          outlined
          rounded
          @click="$router.push('/tag-replacement/')"
        >{{ $t('actions.tagReplacement') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Information',
  data: () => ({
    dialog: false,
    gdpr: false
  }),
  computed: {
    ...mapState(['referenceCode'])
  },
  methods: {
    handleLearningClick () {
      window.location = `/learning/${this.$i18n.locale}/index.html?returnUrl=${encodeURIComponent(
        process.env.VUE_APP_AD_CALLBACK_URL + '/#/?id=' + this.referenceCode
      )}`
    }
  }
}
</script>
